import React from 'react';

const UserAgreement = () => {
    return (
        <div className="flex flex-col justify-center flex-grow bg-blue-900 text-white p-8 pb-24">
            <h1 className="text-2xl font-bold mb-4">User Agreement</h1>
            <p>Welcome to Pure Math. By using our website located at https://www.puremath.co.uk/ ("Site"), you agree to comply with and be bound by the following User Agreement. Please review the following terms carefully. If you do not agree to these terms, you should not use this Site.</p>
            <h2 className="text-xl font-semibold mt-4">1. Description of Service</h2>
            <p>Pure Math is dedicated to helping students improve their math grades through interactive worksheets, personalized learning experiences, and features like exam paper retrieval.</p>
            <h2 className="text-xl font-semibold mt-4">2. User Responsibilities</h2>
            <ul className="list-disc list-inside">
                <li>Users must not resell any resources obtained from Pure Math, including but not limited to topic booklets.</li>
                <li>Users must not engage in any form of theft, impersonation, or other illegal activities.</li>
            </ul>
            <h2 className="text-xl font-semibold mt-4">3. Account Creation</h2>
            <p>To create an account, users must provide a valid email address and a password.</p>
            <h2 className="text-xl font-semibold mt-4">4. Prohibited Activities</h2>
            <ul className="list-disc list-inside">
                <li>Engaging in any form of theft or impersonation.</li>
                <li>Reselling or distributing resources from Pure Math without authorization.</li>
                <li>Engaging in any illegal activities.</li>
            </ul>
            <h2 className="text-xl font-semibold mt-4">5. Intellectual Property</h2>
            <p>All content on this site, including worksheets and topic booklets, is the property of Pure Math. Users may use the content for personal use but are not permitted to release or distribute it without permission.</p>
            <h2 className="text-xl font-semibold mt-4">6. Termination</h2>
            <p>Pure Math reserves the right to terminate user accounts for any violations of this agreement, including theft or impersonation.</p>
            <h2 className="text-xl font-semibold mt-4">7. Changes to This Agreement</h2>
            <p>Pure Math reserves the right to modify this User Agreement at any time. Changes will be posted on this page and your continued use of the Site after such changes constitutes your acceptance of the new terms.</p>
        </div>
    );
};

export default UserAgreement;
