import React from 'react';
import { Link } from 'react-router-dom';
import { getCookie } from "../utils/Cookies";

const Home = () => {
    const jwtToken = getCookie('jwt');

    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-blue-900 text-white">
            {/* Moving Banner */}
            <div className="w-full bg-yellow-400 text-blue-900 py-2 px-4 text-center font-bold animate-pulse">
                <p>We've moved! Please visit us at <a href="https://www.revisewizard.com" className="underline hover:text-blue-700">https://www.revisewizard.com</a></p>
            </div>

            <div className="flex flex-col items-center justify-center flex-grow p-8 pb-24">
                {/* Navigation buttons - Render only if JWT token is not present */}
                {!jwtToken && (
                    <div className="absolute top-16 right-8">
                        <Link to="/signup">
                            <button className="bg-green-500 text-white px-4 py-2 rounded mr-4 hover:bg-green-600 transition duration-300">
                                Sign Up
                            </button>
                        </Link>
                        <Link to="/login">
                            <button className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition duration-300">
                                Login
                            </button>
                        </Link>
                    </div>
                )}

                {/* Content */}
                <div className="text-center">
                    {/* Centered text with animation */}
                    <h1 className="text-4xl font-bold mb-4 transition duration-300 transform hover:scale-110">
                        Welcome to <span className="text-yellow-400">PureMath</span>
                    </h1>
                    <p className="text-lg mb-6">
                        At PureMath, we're dedicated to helping students improve their math grades through interactive
                        topical booklets, personalized learning experiences, and features like exam paper retrieval.
                    </p>
                    {/* Conditional rendering for button based on JWT token */}
                    {jwtToken ? (
                        <Link to="/topic-selector">
                            <button className="bg-green-500 text-white px-8 py-4 rounded hover:bg-green-600 transition duration-300 transform hover:scale-110">
                                Check out the Topic Selector, or click the navigation to see more
                            </button>
                        </Link>
                    ) : (
                        <Link to="/signup">
                            <button className="bg-green-500 text-white px-8 py-4 rounded hover:bg-green-600 transition duration-300 transform hover:scale-110">
                                Sign Up Now
                            </button>
                        </Link>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Home;