import axios from "axios";
import { setJWT } from "./Cookies";
import { NavigateFunction } from "react-router";
import {getAPIUrl, getWebsiteUrl} from "./Url";

export function initializeGoogleSignIn(clientId: string, navigate: NavigateFunction) {
    if (window.google) {
        window.google.accounts.id.initialize({
            client_id: clientId,
            callback: (response: any) => handleCredentialResponse(response, navigate),
        });

        window.google.accounts.id.renderButton(
            document.getElementById("google-signin-button"),
            {
                theme: "outline",
                size: "large",
                text: "continue_with",
                locale: "en",
            }
        );
    }
}

const handleCredentialResponse = async (response: any, navigate: NavigateFunction) => {
    navigate('/google-loading'); // Navigate to the loading page

    if (response.credential) {
        const jwt = response.credential;
        try {
            const apiResponse = await axios.post(`${getAPIUrl()}/auth/google`, {
                jwt: jwt,
                url: getWebsiteUrl()
            });

            if (apiResponse.status === 200) {
                const jwt = apiResponse.data;
                setJWT('jwt', jwt);
                navigate('/')
                window.location.reload();
            } else {
                console.error('Error during Google sign in:', apiResponse.data);
                navigate('/login'); // Navigate back to login page on error
            }
        } catch (error) {
            console.error('Error during Google sign in:', error);
            navigate('/login'); // Navigate back to login page on error
        }
    } else {
        console.error('Error during Google sign in:', response);
        navigate('/login'); // Navigate back to login page on error
    }
};
