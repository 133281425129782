import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';

const LoggingOut = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const timer = setTimeout(() => {
            navigate('/');
            window.location.reload();
        }, 3000); // Adjust the delay as needed

        return () => clearTimeout(timer); // Cleanup timer on component unmount
    }, [navigate]);

    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-blue-900 text-white p-8">
            <h1 className="text-4xl font-extrabold mb-8 text-center">Logging out, please wait...</h1>
        </div>
    );
};

export default LoggingOut;
