import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { motion } from 'framer-motion';
import { FaSpinner } from "@react-icons/all-files/fa/FaSpinner";
import { saveAs } from 'file-saver';
import axios from "axios";
import { getCookie, removeCookie, setCookie } from "../utils/Cookies";
import { getAPIUrl } from "../utils/Url";
import { useNavigate } from "react-router";

const TopicSelector = () => {
    const [selectedOptions, setSelectedOptions] = useState<{ value: string; label: string }[]>([]);
    const [questionsPerTopic, setQuestionsPerTopic] = useState<number>(5); // Default value
    const [topics, setTopics] = useState<{ value: string; label: string }[]>([]);
    const [loadingTopics, setLoadingTopics] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const cachedSelectedLevel = getCookie('selectedLevel');
    const defaultSelectedLevel = cachedSelectedLevel && ['gcse', 'aslevel', 'alevel'].includes(cachedSelectedLevel) ? cachedSelectedLevel : 'gcse';
    const [selectedLevel, setSelectedLevel] = useState<'gcse' | 'aslevel' | 'alevel'>(defaultSelectedLevel as 'gcse' | 'aslevel' | 'alevel');
    const navigate = useNavigate();

    useEffect(() => {
        fetchTopics(selectedLevel);
    }, [selectedLevel]);

    const fetchTopics = async (level: string) => {
        setLoadingTopics(true);
        try {
            const response = await axios.get(`${getAPIUrl()}/topicList`, { params: { level } });
            const topicsData = response.data.topics.map((topic: string) => ({ value: topic, label: topic }));
            setTopics(topicsData);
        } catch (error: any) {
            console.error('Error fetching topics:', error);
            setTopics([]); // Clear topics on error
        } finally {
            setLoadingTopics(false);
        }
    };

    const handleLevelChange = (newLevel: 'gcse' | 'aslevel' | 'alevel') => {
        setSelectedLevel(newLevel);
        setCookie('selectedLevel', newLevel);
        fetchTopics(newLevel);
        setSelectedOptions([]);
    };

    const handleTopicChange = (selectedOptions: any) => {
        setSelectedOptions(selectedOptions);
    };

    const handleQuestionsPerTopicChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(event.target.value, 10);
        setQuestionsPerTopic(isNaN(value) ? 1 : Math.max(1, Math.min(10, value)));
    };

    const customStyles = {
        option: (provided: any, state: any) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#4299e1' : 'white',
            color: state.isSelected ? 'white' : 'black',
        }),
    };

    const handleCreatePaper = async () => {
        setLoading(true);

        try {
            const response = await axios.get(
                getAPIUrl() + `/${selectedOptions.length === 1 ? 'topic' : 'topics'}`,
                {
                    params: {
                        [selectedOptions.length === 1 ? 'topic' : 'topics']: selectedOptions.map(topic => topic.value).join(','),
                        year: selectedLevel,
                        amount: questionsPerTopic,
                        jwt: getCookie('jwt') || '',
                    },
                    responseType: 'blob',
                }
            );

            if (response.data.size === 0) {
                alert('No questions found for the selected topics');
                return;
            }

            const blob = new Blob([response.data], { type: 'application/pdf' });
            saveAs(blob, 'topic_paper.pdf');
        } catch (error: any) {
            handleError(error);
        } finally {
            setLoading(false);
        }
    };

    const handleError = (error: any) => {
        if (error.response) {
            if (error.response.status === 489) {
                alert('Invalid JWT token, redirecting to home page...');
                removeCookie('jwt');
                navigate('/');
                window.location.reload();
            } else if (error.response.status === 401) {
                alert('Unauthorized access. Please log in again.');
                removeCookie('jwt');
                navigate('/');
            } else {
                alert('An error occurred while fetching the PDF');
            }
        } else {
            alert('An unexpected error occurred');
        }
    };

    return (
        <div className="flex flex-col items-center justify-center flex-grow bg-blue-900 text-white p-8 pb-24">
            <h1 className="text-4xl font-extrabold mb-8 text-center">
                {selectedLevel === 'gcse'
                    ? 'GCSE Topic Paper Generator'
                    : selectedLevel === 'alevel'
                        ? 'A-level Topic Paper Generator'
                        : 'AS-level Topic Paper Generator'}
            </h1>
            <div className="mb-4 space-x-4 flex items-center">
                <label
                    className={`inline-flex items-center px-4 py-2 rounded-full cursor-pointer ${
                        selectedLevel === 'gcse' ? 'bg-green-500 text-white' : 'bg-white text-green-500 hover:bg-blue-500 hover:text-white'
                    }`}
                >
                    <input
                        type="radio"
                        className="hidden"
                        value="gcse"
                        checked={selectedLevel === 'gcse'}
                        onChange={() => handleLevelChange('gcse')}
                    />
                    GCSE
                </label>

                <label
                    className={`inline-flex items-center px-4 py-2 rounded-full cursor-pointer ${
                        selectedLevel === 'aslevel' ? 'bg-green-500 text-white' : 'bg-white text-green-500 hover:bg-blue-500 hover:text-white'
                    }`}
                >
                    <input
                        type="radio"
                        className="hidden"
                        value="aslevel"
                        checked={selectedLevel === 'aslevel'}
                        onChange={() => handleLevelChange('aslevel')}
                    />
                    AS-level
                </label>

                <label
                    className={`inline-flex items-center px-4 py-2 rounded-full cursor-pointer ${
                        selectedLevel === 'alevel' ? 'bg-green-500 text-white' : 'bg-white text-green-500 hover:bg-blue-500 hover:text-white'
                    }`}
                >
                    <input
                        type="radio"
                        className="hidden"
                        value="alevel"
                        checked={selectedLevel === 'alevel'}
                        onChange={() => handleLevelChange('alevel')}
                    />
                    A-level
                </label>
            </div>

            <div className="mb-4 w-96">
                <label className="block text-lg mb-2">Select Topics:</label>
                {loadingTopics ? (
                    <div className="flex justify-center items-center">
                        <FaSpinner className="animate-spin" />
                    </div>
                ) : (
                    <Select isMulti options={topics} value={selectedOptions} onChange={handleTopicChange} styles={customStyles} />
                )}
            </div>

            <div className="mb-4 w-96">
                <label className="block text-lg mb-2">Number of Questions per Topic:</label>
                <input
                    type="number"
                    value={questionsPerTopic}
                    onChange={handleQuestionsPerTopicChange}
                    className="w-full px-4 py-2 rounded text-black" // Set text color to black
                    min="1"
                    max="10"
                />
            </div>

            <div className="mb-4 w-96 p-4 bg-amber-500 text-black text-center rounded">
                Please note that booklet creation will be slow. Thank you for your patience!
            </div>

            <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className={`bg-green-500 text-white px-4 py-2 rounded transition duration-300 transform hover:scale-105 focus:outline-none ${
                    selectedOptions.length > 0 ? 'bg-purple-700' : ''
                }`}
                onClick={handleCreatePaper}
                disabled={loading || selectedOptions.length === 0} // Disable button if loading or no options selected
            >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {loading && (
                        <motion.div
                            animate={{
                                rotate: 360,
                                transition: {
                                    duration: 1,
                                    ease: 'linear',
                                    repeat: Infinity,
                                },
                            }}
                            style={{ marginRight: '0.5rem' }}
                        >
                            <FaSpinner />
                        </motion.div>
                    )}
                    {loading ? 'Generating...' : 'Generate Topical Paper'}
                </div>
            </motion.button>
        </div>
    );
};

export default TopicSelector;
