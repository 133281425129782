import { fallDown as Menu } from 'react-burger-menu';
import "../utils/styles/sidebar.css";
import { useNavigate } from "react-router";
import useAuth from "../utils/useAuth";

const MainNavbar = () => {
    const navigate = useNavigate();
    const { role, logout } = useAuth(navigate);

    const handleClick = (path: string) => {
        navigate(path);
    };

    return (
        <Menu>
            <button className="bm-item home" onClick={() => handleClick('/')}>Home</button>
            <button className="bm-item topic-selector" onClick={() => handleClick('/topic-selector')}>Topic Selector</button>
            {role === 'ADMIN' && <button className="bm-item question-uploader" onClick={() => handleClick('/question-uploader')}>Question Uploader</button>}
            <button className="bm-item exam-paper-retriever" onClick={() => handleClick('/exam-paper-retriever')}>Exam Paper Retriever</button>
            <button className="bm-item logout" onClick={logout}>Logout</button>
        </Menu>
    );
};

export default MainNavbar;
