import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <footer className="bg-gray-800 text-white py-4 w-full">
            <div className="flex flex-wrap justify-center gap-4 text-gray-400">
                <p>&copy; 2024 PureMath. All rights reserved.</p>
                <Link to="/user-agreement" className="cursor-pointer hover:underline">User Agreement</Link>
                <Link to="/terms-of-service" className="cursor-pointer hover:underline">Terms of Service</Link>
                <Link to="/privacy-policy" className="cursor-pointer hover:underline">Privacy Policy</Link>
            </div>
        </footer>
    );
};

export default Footer;