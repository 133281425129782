import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import axios from 'axios';
import {getAPIUrl, getWebsiteUrl} from '../../utils/Url';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import { Link } from 'react-router-dom';
import { getCookie } from '../../utils/Cookies';
import { useNavigate } from 'react-router';
import { initializeGoogleSignIn } from '../../utils/googleSignIn';

const Login: React.FC = () => {
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [error, setError] = useState<string>(''); // State variable for error message
    const [hcaptchaToken, setHecaptchaToken] = useState<string>('');
    const [isVerifying, setIsVerifying] = useState<boolean>(false); // State to track verification process
    const [isLoggingIn, setIsLoggingIn] = useState<boolean>(false); // State to track login process
    const jwtToken = getCookie('jwt');
    const navigate = useNavigate();

    useEffect(() => {
        initializeGoogleSignIn(process.env.REACT_APP_GOOGLE_CLIENT_ID || '', navigate);
    }, [navigate]);

    const handleVerificationSuccess = async (token: string) => {
        setIsVerifying(true);
        try {
            const response = await axios.post(`${getAPIUrl()}/verify-hcaptcha`, {
                hcaptchaToken: token,
                url: getWebsiteUrl()
            });

            if (response.status === 200) {
                console.log('HCaptcha token verified');
                setHecaptchaToken(token);
                setError('');
            } else {
                console.error('Error during hcaptcha verification:', response.data);
                setError('An error occurred during hcaptcha verification');
            }
        } catch (error) {
            console.error('Error during hcaptcha verification:', error);
            setError('An error occurred during hcaptcha verification');
        } finally {
            setIsVerifying(false);
        }
    };

    const handleLogin = async () => {
        if (!email.trim()) {
            setError('Email cannot be empty');
            return;
        }

        if (!password.trim()) {
            setError('Password cannot be empty');
            return;
        }

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!emailRegex.test(email)) {
            setError('Invalid email address');
            return;
        }

        if (!hcaptchaToken) {
            setError('Please complete the CAPTCHA');
            return;
        }

        setIsLoggingIn(true);

        await axios.post(`${getAPIUrl()}/login`, {
            email: email,
            password: password,
            hcaptchaToken: hcaptchaToken,
            url: getWebsiteUrl()
        })
            .then((response: any) => {
                if (response.status === 200) {
                    navigate(`/verification/${email}/login`);
                }
            })
            .catch((error: any) => {
                if (error.response.status === 400) {
                    setError('Invalid password');
                } else if (error.response.status === 404) {
                    setError('Email does not exist');
                } else if (error.response.status === 406) {
                    setError('Email is using OAuth authentication, please login with Google or reset your password');
                } else {
                    setError('An error occurred during login');
                    console.error('Error during login:', error);
                }
            })
            .finally(() => {
                setIsLoggingIn(false);
            });
    };

    return (
        <div className="bg-blue-900 text-white min-h-screen flex flex-col items-center justify-center overflow-hidden">
            {!jwtToken && (
                <div className="absolute top-8 right-8">
                    <Link to="/">
                        <button
                            className="bg-green-500 text-white px-4 py-2 rounded mr-4 hover:bg-green-600 transition duration-300">Home
                        </button>
                    </Link>
                    <Link to="/signup">
                        <button
                            className="bg-green-500 text-white px-4 py-2 rounded mr-4 hover:bg-green-600 transition duration-300">Sign
                            Up
                        </button>
                    </Link>
                </div>
            )}

            <motion.h1
                initial={{opacity: 0, y: -20}}
                animate={{opacity: 1, y: 0}}
                transition={{duration: 0.5}}
                className="text-4xl font-extrabold mb-8 text-center"
            >
                Login
            </motion.h1>

            <div id="google-signin-button"></div>

            {error && ( // Conditionally render error message
                <div className="bg-red-500 text-white px-4 py-2 rounded mb-4 w-96 text-center">
                    {error}
                </div>
            )}

            <div className="mb-4 w-96">
                <label className="block text-lg mb-2">Email:</label>
                <input
                    type="text"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="w-full px-4 py-2 rounded text-black"
                />
            </div>

            <div className="mb-2 w-96">
                <label className="block text-lg mb-2">Password:</label>
                <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="w-full px-4 py-2 rounded text-black"
                />
            </div>

            <div className="mb-4 w-96 text-center">
                <Link to="/reset-password-request"
                      className="text-blue-300 hover:text-blue-500 transition duration-300">
                    Forgot your password? Click here
                </Link>
            </div>

            <div className="mb-4">
                <HCaptcha
                    sitekey={process.env.REACT_APP_HCAPTCHA_SITE_KEY || ''}
                    onVerify={(token) => handleVerificationSuccess(token)}
                />
            </div>

            <motion.button
                whileHover={{scale: 1.05}}
                whileTap={{scale: 0.95}}
                className="bg-green-500 text-white px-4 py-2 rounded transition duration-300"
                onClick={handleLogin}
                disabled={isVerifying || isLoggingIn} // Disable button while verifying or logging in
            >
                {isVerifying ? 'Please wait...' : isLoggingIn ? (
                    <div className="flex space-x-1">
                        <span className="animate-bounce">.</span>
                        <span className="animate-bounce animation-delay-200">.</span>
                        <span className="animate-bounce animation-delay-400">.</span>
                    </div>
                ) : 'Login'} {/* Change button text based on states */}
            </motion.button>
        </div>
    );
};

export default Login;
