import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { motion } from 'framer-motion';
import { FaSpinner } from "@react-icons/all-files/fa/FaSpinner";
import { saveAs } from 'file-saver';
import axios from "axios";
import { getCookie, removeCookie, setCookie } from "../utils/Cookies";
import { getAPIUrl } from "../utils/Url";
import { useNavigate } from "react-router";

const ExamPaperRetriever = () => {
    const [selectedLevel, setSelectedLevel] = useState<'gcse' | 'aslevel' | 'alevel'>('gcse');
    const [selectedExamBoard, setSelectedExamBoard] = useState<string | null>(null);
    const [selectedPaper, setSelectedPaper] = useState<{ value: string; label: string } | null>(null);
    const [selectedYear, setSelectedYear] = useState<{ value: number; label: string } | null>(null);
    const [selectedMonth, setSelectedMonth] = useState<{ value: string; label: string } | null>(null);
    const [examBoards, setExamBoards] = useState<string[]>([]);
    const [papers, setPapers] = useState<{ value: string; label: string }[]>([]);
    const [years, setYears] = useState<{ value: number; label: string }[]>([]);
    const [months, setMonths] = useState<{ value: string; label: string }[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingPapers, setLoadingPapers] = useState<boolean>(false);
    const navigate = useNavigate();

    useEffect(() => {
        fetchExamBoards();
    }, [selectedLevel]);

    const fetchExamBoards = async () => {
        try {
            const response = await axios.get(`${getAPIUrl()}/pastPaperExamBoards`);
            setExamBoards(response.data);
        } catch (error: any) {
            console.error('Error fetching exam boards:', error);
            setExamBoards([]); // Clear exam boards on error
        }
    };

    const fetchPapers = async (examBoard: string, level: string) => {
        setLoadingPapers(true);
        try {
            const response = await axios.get(`${getAPIUrl()}/pastPaperInfo`, { params: { examBoard, level } });
            const data = response.data;
            const papersData = data.names.map((paper: string) => ({ value: paper, label: paper }));
            const yearsData = data.years.map((year: number) => ({ value: year, label: year.toString() }));
            const monthsData = data.months.map((month: string) => ({ value: month, label: month }));
            setPapers(papersData);
            setYears(yearsData);
            setMonths(monthsData);
        } catch (error: any) {
            console.error('Error fetching papers:', error);
            setPapers([]); // Clear papers on error
            setYears([]); // Clear years on error
            setMonths([]); // Clear months on error
        } finally {
            setLoadingPapers(false);
        }
    };

    const handleLevelChange = (newLevel: 'gcse' | 'aslevel' | 'alevel') => {
        setSelectedLevel(newLevel);
        setCookie('selectedLevel', newLevel);
        setSelectedExamBoard(null);
        setSelectedPaper(null);
        setSelectedYear(null);
        setSelectedMonth(null);
        setPapers([]);
        setYears([]);
        setMonths([]);
    };

    const handleExamBoardChange = (examBoard: string) => {
        setSelectedExamBoard(examBoard);
        fetchPapers(examBoard, selectedLevel);
    };

    const handlePaperChange = (selectedOption: any) => {
        setSelectedPaper(selectedOption);
    };

    const handleYearChange = (selectedOption: any) => {
        setSelectedYear(selectedOption);
    };

    const handleMonthChange = (selectedOption: any) => {
        setSelectedMonth(selectedOption);
    };

    const handleRetrievePaper = async () => {
        if (!selectedExamBoard || !selectedLevel || !selectedPaper || !selectedYear || !selectedMonth) return;

        setLoading(true);

        const requestData = {
            examBoard: selectedExamBoard,
            level: selectedLevel,
            year: selectedYear.value,
            paperName: selectedPaper.value,
            month: selectedMonth.value,
            jwt: getCookie('jwt') || '',
        };

        try {
            await axios.post(`${getAPIUrl()}/pastpaper`, requestData, { responseType: 'blob' })
                .then((response: any) => {
                    if (response.data.size === 0) {
                        alert('No paper found for the selected options');
                        return;
                    }

                    const blob = new Blob([response.data], { type: 'application/pdf' });
                    saveAs(blob, `${selectedPaper.value}-${selectedMonth.value}-${selectedYear.value}.pdf`);
                })
                .catch((error: any) => {
                    if (error.response.status === 489) {
                        alert('Invalid JWT token, redirecting to home page...');
                        removeCookie('jwt');
                        navigate('/');
                        window.location.reload();
                    } else {
                        alert('An error occurred while fetching the PDF');
                    }
                });

        } catch (error) {
            console.error('Error during paper retrieval:', error);
        } finally {
            setLoading(false);
        }
    };

    const customStyles = {
        option: (provided: any, state: any) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#4299e1' : 'white',
            color: state.isSelected ? 'white' : 'black',
        }),
    };

    return (
        <div className="flex flex-col items-center justify-center flex-grow bg-blue-900 text-white p-8 pb-24">
            <h1 className="text-4xl font-extrabold mb-8 text-center">
                {selectedLevel === 'gcse'
                    ? 'GCSE Exam Paper Retriever'
                    : selectedLevel === 'alevel'
                        ? 'A-level Exam Paper Retriever'
                        : 'AS-level Exam Paper Retriever'}
            </h1>
            <div className="mb-4 space-x-4 flex items-center">
                <label
                    className={`inline-flex items-center px-4 py-2 rounded-full cursor-pointer ${
                        selectedLevel === 'gcse' ? 'bg-green-500 text-white' : 'bg-white text-green-500 hover:bg-blue-500 hover:text-white'
                    }`}
                >
                    <input
                        type="radio"
                        className="hidden"
                        value="gcse"
                        checked={selectedLevel === 'gcse'}
                        onChange={() => handleLevelChange('gcse')}
                    />
                    GCSE
                </label>

                <label
                    className={`inline-flex items-center px-4 py-2 rounded-full cursor-pointer ${
                        selectedLevel === 'aslevel' ? 'bg-green-500 text-white' : 'bg-white text-green-500 hover:bg-blue-500 hover:text-white'
                    }`}
                >
                    <input
                        type="radio"
                        className="hidden"
                        value="aslevel"
                        checked={selectedLevel === 'aslevel'}
                        onChange={() => handleLevelChange('aslevel')}
                    />
                    AS-level
                </label>

                <label
                    className={`inline-flex items-center px-4 py-2 rounded-full cursor-pointer ${
                        selectedLevel === 'alevel' ? 'bg-green-500 text-white' : 'bg-white text-green-500 hover:bg-blue-500 hover:text-white'
                    }`}
                >
                    <input
                        type="radio"
                        className="hidden"
                        value="alevel"
                        checked={selectedLevel === 'alevel'}
                        onChange={() => handleLevelChange('alevel')}
                    />
                    A-level
                </label>
            </div>

            <div className="mb-4 w-96">
                <label className="block text-lg mb-2">Select Exam Board:</label>
                <div className="flex space-x-4">
                    {examBoards.map((board) => (
                        <button
                            key={board}
                            className={`px-4 py-2 rounded-full cursor-pointer ${
                                selectedExamBoard === board ? 'bg-green-500 text-white' : 'bg-white text-green-500 hover:bg-blue-500 hover:text-white'
                            }`}
                            onClick={() => handleExamBoardChange(board)}
                        >
                            {board}
                        </button>
                    ))}
                </div>
            </div>

            {selectedExamBoard && !loadingPapers && (
                <>
                    <div className="mb-4 w-96">
                        <label className="block text-lg mb-2">Select Paper:</label>
                        <Select options={papers} value={selectedPaper} onChange={handlePaperChange} styles={customStyles} />
                    </div>

                    <div className="mb-4 w-96">
                        <label className="block text-lg mb-2">Select Year:</label>
                        <Select options={years} value={selectedYear} onChange={handleYearChange} styles={customStyles} />
                    </div>

                    <div className="mb-4 w-96">
                        <label className="block text-lg mb-2">Select Month:</label>
                        <Select options={months} value={selectedMonth} onChange={handleMonthChange} styles={customStyles} />
                    </div>
                </>
            )}

            {loadingPapers && (
                <div className="flex justify-center items-center">
                    <FaSpinner className="animate-spin" />
                </div>
            )}

            {selectedExamBoard && selectedPaper && selectedYear && selectedMonth && (
                <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    className={`bg-green-500 text-white px-4 py-2 rounded transition duration-300 transform hover:scale-105 focus:outline-none ${
                        loading ? 'bg-purple-700' : ''
                    }`}
                    onClick={handleRetrievePaper}
                    disabled={loading}
                >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {loading && (
                            <motion.div
                                animate={{
                                    rotate: 360,
                                    transition: {
                                        duration: 1,
                                        ease: 'linear',
                                        repeat: Infinity,
                                    },
                                }}
                                style={{ marginRight: '0.5rem' }}
                            >
                                <FaSpinner />
                            </motion.div>
                        )}
                        {loading ? 'Retrieving...' : 'Retrieve Exam Paper'}
                    </div>
                </motion.button>
            )}
        </div>
    );
};

export default ExamPaperRetriever;
