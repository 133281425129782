import { fallDown as Menu } from 'react-burger-menu';
import "../utils/styles/sidebar.css";
import { useNavigate} from "react-router";

const LoginNavbar = () => {
    const navigate = useNavigate();

    const handleClick = (path: string) => {
        navigate(path);
    };

    return (
        <Menu>
            <button className="home" onClick={() => handleClick(`/`)}>Home</button>
            <button className="login" onClick={() => handleClick(`/login`)}>Login</button>
            <button className="signup" onClick={() => handleClick(`/signup`)}>Sign Up</button>
        </Menu>
    );
};

export default LoginNavbar;
