import React from 'react';

const GoogleLoading = () => {
    return (
        <div className="bg-blue-900 text-white min-h-screen flex flex-col items-center justify-center overflow-hidden">
            <h1 className="text-4xl font-extrabold mb-8 text-center">Loading...</h1>
            <div className="flex space-x-1">
                <span className="animate-bounce">.</span>
                <span className="animate-bounce animation-delay-200">.</span>
                <span className="animate-bounce animation-delay-400">.</span>
            </div>
        </div>
    );
};

export default GoogleLoading;