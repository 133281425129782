import React, { useState, useRef } from 'react';
import axios from 'axios';
import {getAPIUrl, getWebsiteUrl} from "../../utils/Url";
import { useNavigate, useParams } from "react-router";
import { setJWT } from "../../utils/Cookies";

const Verification = () => {
    const { email, verificationType } = useParams<{ email: string; verificationType: string; }>();

    const [verificationCode, setVerificationCode] = useState(['', '', '', '', '', '']);
    const [error, setError] = useState('');
    const [info, setInfo] = useState(''); // State variable to show info message
    const [isVerifying, setIsVerifying] = useState(false); // State to track verification process
    const navigate = useNavigate();
    const inputRefs = useRef<HTMLInputElement[]>([]);

    const handleVerification = async () => {
        setIsVerifying(true);
        try {
            const response = await axios.post(`${getAPIUrl()}/verify`, {
                email: email,
                verificationCode: verificationCode.join(''),
                type: verificationType,
                url: getWebsiteUrl()
            });

            if (response.status === 200) {
                if (verificationType === 'signup') {
                    setInfo('Account verified, please login');
                    navigate('/login');
                } else {
                    setInfo('Successfully verified, logging in');
                    let jwt = response.data;
                    setJWT('jwt', jwt);
                    navigate('/');
                    window.location.reload();
                }

                setError('');
                setInfo('');
                setVerificationCode(['', '', '', '', '', '']);
            } else {
                // Handle unsuccessful verification
                console.error('Error during verification:', response.data);
                setError('Invalid verification code');
            }
        } catch (error) {
            // Handle error
            console.error('Error during verification:', error);
            setError('Invalid verification code');
        } finally {
            setIsVerifying(false);
        }
    };

    // Function to handle the change in each input box
    const handleChange = (index: number, value: string) => {
        const newVerificationCode = [...verificationCode];
        newVerificationCode[index] = value;
        setVerificationCode(newVerificationCode);

        // Move focus to next input box if value is entered
        if (value && index < 5) {
            inputRefs.current[index + 1].focus();
        }
    };

    // Function to handle key down event
    const handleKeyDown = (index: number, event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Backspace' && !verificationCode[index] && index > 0) {
            inputRefs.current[index - 1].focus();
        }
    };

    // Function to handle paste event
    const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
        e.preventDefault();
        const clipboardData = e.clipboardData.getData('text');
        const pastedData = clipboardData.slice(0, 6);
        const pastedChars = pastedData.split('');
        const newVerificationCode = [...verificationCode];
        for (let i = 0; i < pastedChars.length; i++) {
            newVerificationCode[i] = pastedChars[i];
            if (inputRefs.current[i]) {
                inputRefs.current[i].value = pastedChars[i];
            }
        }
        setVerificationCode(newVerificationCode);
        if (pastedChars.length < 6) {
            inputRefs.current[pastedChars.length].focus();
        }
    };

    return (
        <div className="bg-blue-900 text-black min-h-screen flex flex-col items-center justify-center overflow-hidden">
            <h2 className="text-white text-2xl mb-4">Enter verification code:</h2> {/* Heading added */}
            <div className="flex justify-center items-center mb-4">
                {Array.from({ length: 6 }, (_, index) => (
                    <input
                        key={index}
                        type="text"
                        maxLength={1}
                        value={verificationCode[index]}
                        onChange={(e) => handleChange(index, e.target.value)}
                        onKeyDown={(e) => handleKeyDown(index, e)}
                        onPaste={handlePaste}
                        ref={el => inputRefs.current[index] = el!}
                        inputMode="text"
                        autoCapitalize="off"
                        className="w-12 h-12 border border-gray-300 rounded-md text-3xl mx-2 text-center focus:outline-none focus:border-blue-500"
                    />
                ))}
            </div>
            <button
                onClick={handleVerification}
                className="bg-green-500 text-white px-4 py-2 rounded transition duration-300 hover:bg-green-600"
                disabled={isVerifying}
            >
                {isVerifying ? (
                    <div className="flex space-x-1">
                        <span className="animate-bounce">.</span>
                        <span className="animate-bounce animation-delay-200">.</span>
                        <span className="animate-bounce animation-delay-400">.</span>
                    </div>
                ) : 'Verify'}
            </button>
            {error && <p className="text-red-500 mt-2">{error}</p>}
            {info && <p className="text-green-500 mt-2">{info}</p>}
        </div>
    );
};

export default Verification;
