import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {getAPIUrl, getWebsiteUrl} from '../../utils/Url';
import { useNavigate, useParams } from 'react-router';
import { passwordStrength } from "check-password-strength";
import { motion } from 'framer-motion';

const ResetPassword = () => {
    const { token } = useParams<{ token: string }>();
    const [email, setEmail] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [isResetting, setIsResetting] = useState(false);
    const [isValidating, setIsValidating] = useState(true);
    const [isTokenValid, setIsTokenValid] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const validateToken = async () => {
            try {
                const response = await axios.post(`${getAPIUrl()}/verify-password-reset-token`, {
                    token: token,
                    url: getWebsiteUrl()
                });

                if (response.status === 200) {
                    setIsTokenValid(true);
                } else {
                    setError('Invalid or expired password reset link');
                }
            } catch (error : any) {
                setError('Invalid or expired password reset link');
                setTimeout(() => {
                    navigate('/404');
                }, 3000);
            } finally {
                setIsValidating(false);
            }
        };

        if (token) {
            validateToken();
        } else {
            setError('Invalid password reset link');
            setTimeout(() => {
                navigate('/404');
            }, 3000);
        }
    }, [token, navigate]);

    const handleResetPassword = async () => {
        if (!email.trim()) {
            setError('Email cannot be empty');
            return;
        }

        if (!newPassword.trim() || !confirmPassword.trim()) {
            setError('Password and confirm password cannot be empty');
            return;
        }

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!emailRegex.test(email)) {
            setError('Invalid email address, must be in the form of an email address');
            return;
        }

        // Password security requirements
        if (passwordStrength(newPassword).id !== 3) {
            setError('Password must contain at least one lowercase letter, one uppercase letter, one number, one special character, and be at least 8 characters long');
            return;
        }

        if (newPassword !== confirmPassword) {
            setError('Passwords do not match');
            return;
        }

        setIsResetting(true);

        await axios.post(`${getAPIUrl()}/reset-password`, {
            email: email,
            token: token,
            newPassword: newPassword,
            url: getWebsiteUrl()
        })
            .then((response : any) => {
                if (response.status === 200) {
                    navigate(`/login`);
                }
            })
            .catch((error : any) => {
                console.error('Error during password reset:', error);
                setError('An error occurred during password reset');
            })
            .finally(() => {
                setIsResetting(false);
            });
    };

    if (isValidating) {
        return (
            <div className="bg-blue-900 text-white min-h-screen flex flex-col items-center justify-center overflow-hidden">
                <motion.h1
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                    className="text-4xl font-extrabold mb-8 text-center"
                >
                    Please wait while we validate your request...
                </motion.h1>
            </div>
        );
    }

    if (!isTokenValid) {
        return null;
    }

    return (
        <div className="bg-blue-900 text-white min-h-screen flex flex-col items-center justify-center overflow-hidden">
            <h1 className="text-4xl font-extrabold mb-8 text-center">Reset Password</h1>

            {error && (
                <div className="bg-red-500 text-white px-4 py-2 rounded mb-4 w-96 text-center">
                    {error}
                </div>
            )}

            <div className="mb-4 w-96">
                <label className="block text-lg mb-2">Email:</label>
                <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="w-full px-4 py-2 rounded text-black"
                />
            </div>

            <div className="mb-4 w-96">
                <label className="block text-lg mb-2">New Password:</label>
                <input
                    type="password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    className="w-full px-4 py-2 rounded text-black"
                />
            </div>

            <div className="mb-4 w-96">
                <label className="block text-lg mb-2">Confirm Password:</label>
                <input
                    type="password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    className="w-full px-4 py-2 rounded text-black"
                />
            </div>

            <button
                className="bg-green-500 text-white px-4 py-2 rounded transition duration-300"
                onClick={handleResetPassword}
                disabled={isResetting}
            >
                {isResetting ? 'Resetting...' : 'Reset Password'}
            </button>
        </div>
    );
};

export default ResetPassword;
