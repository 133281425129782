import React from 'react';

const TermsOfService = () => {
    return (
        <div className="flex flex-col justify-center flex-grow bg-blue-900 text-white p-8 pb-24">
            <h1 className="text-2xl font-bold mb-4">Terms of Service</h1>
            <p>These Terms of Service ("Terms") govern your use of the Pure Math website located at https://www.puremath.co.uk/ ("Site"). By using our Site, you agree to these Terms. If you do not agree to these Terms, you should not use this Site.</p>
            <h2 className="text-xl font-semibold mt-4">1. User Eligibility</h2>
            <p>There are no specific eligibility requirements for users.</p>
            <h2 className="text-xl font-semibold mt-4">2. Account Creation and Termination</h2>
            <ul className="list-disc list-inside">
                <li>Users must provide a valid email address and a password to create an account.</li>
                <li>Pure Math reserves the right to terminate accounts for violations such as theft or impersonation.</li>
            </ul>
            <h2 className="text-xl font-semibold mt-4">3. Payment Terms</h2>
            <p>Our payment services are coming soon but are not currently available.</p>
            <h2 className="text-xl font-semibold mt-4">4. Intellectual Property</h2>
            <p>All content on this site, including worksheets and topic booklets, is the property of Pure Math. Users may use the content for personal use but are not permitted to release or distribute it without permission.</p>
            <h2 className="text-xl font-semibold mt-4">5. Limitation of Liability</h2>
            <p>Pure Math is not liable for any damages that may occur from the use of our services.</p>
            <h2 className="text-xl font-semibold mt-4">6. Dispute Resolution</h2>
            <p>In the event of a dispute, Pure Math will issue a cease and desist letter. If the issue is not resolved, we will pursue the matter in court.</p>
            <h2 className="text-xl font-semibold mt-4">7. Changes to These Terms</h2>
            <p>Pure Math reserves the right to modify these Terms at any time. Changes will be posted on this page and your continued use of the Site after such changes constitutes your acceptance of the new terms.</p>
        </div>
    );
};

export default TermsOfService;
