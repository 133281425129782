import { useEffect, useState } from "react";
import axios from "axios";
import { getCookie, removeCookie } from "./Cookies";
import { getAPIUrl } from "./Url";
import { NavigateFunction } from "react-router";
import {jwtDecode} from "jwt-decode";

const useAuth = (navigate: NavigateFunction | null) => {
    const [jwt, setJwt] = useState<string | null>(getCookie('jwt'));
    const [role, setRole] = useState<string | null>(null);

    const isTokenExpired = (token: string) => {
        const decoded: any = jwtDecode(token);
        return decoded.exp * 1000 < Date.now();
    };

    const fetchUserRole = async (token: string): Promise<string> => {
        try {
            const response = await axios.post(`${getAPIUrl()}/user-role`, { jwt: token });
            return response.data.role;
        } catch (error) {
            console.error(error); // Log the error for debugging
            return '';
        }
    };

    useEffect(() => {
        if (jwt && isTokenExpired(jwt)) {
            removeCookie('jwt');
            setJwt(null);
            window.location.reload();
        } else if (jwt) {
            fetchUserRole(jwt).then((role) => setRole(role));
        }
    }, [jwt]);

    const logout = () => {
        removeCookie('jwt');
        setJwt(null);

        if (navigate) {
            navigate('/logging-out'); // Navigate to LoggingOut page
        }
    };

    return { jwt, role, logout };
};

export default useAuth;
