import React from 'react';

const PrivacyPolicy = () => {
    return (
        <div className="flex flex-col justify-center flex-grow bg-blue-900 text-white p-8 pb-24">
            <h1 className="text-2xl font-bold mb-4">Privacy Policy</h1>
            <p>Pure Math ("we", "our", "us") is committed to protecting your privacy. This Privacy Policy explains how
                we collect, use, and protect your personal information.</p>
            <h2 className="text-xl font-semibold mt-4">1. Information We Collect</h2>
            <p>We collect the following information:</p>
            <ul className="list-disc list-inside">
                <li>Email address</li>
                <li>Password (encrypted using ARAGON_2, with salt and pepper)</li>
            </ul>
            <h2 className="text-xl font-semibold mt-4">2. How We Use Your Information</h2>
            <ul className="list-disc list-inside">
                <li>Passwords are encrypted using ARAGON_2 with salt and pepper.</li>
                <li>We use your email address solely to determine the number of users we have. We do not use your email
                    for any other purposes.
                </li>
            </ul>
            <h2 className="text-xl font-semibold mt-4">3. Data Sharing</h2>
            <p>We do not share your data with any third parties.</p>
            <h2 className="text-xl font-semibold mt-4">4. User Rights</h2>
            <ul className="list-disc list-inside">
                <li>Users have the right to reset their password.</li>
                <li>Users have the right to access the site.</li>
            </ul>
            <h2 className="text-xl font-semibold mt-4">5. Data Security</h2>
            <p>We use ARAGON_2 encryption with salt and pepper to protect your passwords.</p>
            <h2 className="text-xl font-semibold mt-4">6. Changes to This Privacy Policy</h2>
            <p>We may update this Privacy Policy from time to time. Changes will be posted on this page and your
                continued use of the Site after such changes constitutes your acceptance of the new terms.</p>
        </div>
    );
};

export default PrivacyPolicy;