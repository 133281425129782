import React from 'react';
import { Route, Routes } from "react-router";
import Home from "./pages/Home";
import TopicSelector from "./pages/TopicSelector";
import Login from "./pages/oauth/Login";
import SignUp from "./pages/oauth/SignUp";
import MainNavbar from "./components/MainNavbar";
import LoginNavbar from "./components/LoginNavbar";
import Verification from "./pages/oauth/Verification";
import NotFound from "./pages/NotFound";
import ResetPasswordRequest from "./pages/oauth/ResetPasswordRequest";
import ResetPassword from "./pages/oauth/ResetPassword";
import QuestionUploader from "./pages/QuestionUploader";
import useAuth from "./utils/useAuth";
import ExamPaperRetriever from "./pages/ExamPaperRetriever";
import UserAgreement from "./pages/UserAgreement";
import TermsOfService from "./pages/TermsOfService";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Footer from "./components/Footer";
import GoogleLoading from "./components/GoogleLoading";
import LoggingOut from "./pages/oauth/LoggingOut"; // Import LoggingOut component

declare global {
    interface Window {
        google: any;
    }
}

function App() {
    const { jwt, role } = useAuth(null);
    return (
        <div className="app-container">
            {jwt ? <MainNavbar /> : <LoginNavbar />}
            <div className="content">
                <Routes>
                    {jwt ? (
                        <>
                            <Route path="/" element={<Home />} />
                            <Route path="/topic-selector" element={<TopicSelector />} />
                            <Route path="/exam-paper-retriever" element={<ExamPaperRetriever />} />
                            {role === 'ADMIN' && <Route path="/question-uploader" element={<QuestionUploader />} />}
                            <Route path="/user-agreement" element={<UserAgreement />} />
                            <Route path="/terms-of-service" element={<TermsOfService />} />
                            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                            <Route path="/logging-out" element={<LoggingOut />} /> {/* Add this route */}
                        </>
                    ) : (
                        <>
                            <Route path="/" element={<Home />} />
                            <Route path="/google-loading" element={<GoogleLoading />} />
                            <Route path="/login" element={<Login />} />
                            <Route path="/signup" element={<SignUp />} />
                            <Route path="/verification/:email/:verificationType" element={<Verification />} />
                            <Route path="/reset-password-request" element={<ResetPasswordRequest />} />
                            <Route path="/reset-password/:token" element={<ResetPassword />} />
                            <Route path="/user-agreement" element={<UserAgreement />} />
                            <Route path="/terms-of-service" element={<TermsOfService />} />
                            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                            <Route path="/logging-out" element={<LoggingOut />} /> {/* Add this route */}
                        </>
                    )}
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </div>
            <Footer />
        </div>
    );
}

export default App;
