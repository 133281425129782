import React from 'react';
import {Link} from 'react-router-dom';

const NotFound = () => {
    return (
        <div className="bg-blue-900 text-white min-h-screen flex flex-col justify-center items-center overflow-hidden">
            <h1 className="text-4xl font-bold mb-4">404 - Page Not Found</h1>
            <p className="text-lg mb-6">Oops! The page you are looking for does not exist.</p>
            <Link to="/">
                <button
                    className="bg-green-500 text-white px-8 py-4 rounded hover:bg-green-600 transition duration-300 transform hover:scale-110">Go
                    to Home
                </button>
            </Link>
        </div>
    );
};

export default NotFound;
